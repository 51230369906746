import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { AuthProvider } from './components/AuthContext';
import Login from './components/Login';
import SignUp from './components/SignUp';
import WalletPage from './components/WalletPage';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import SwapPage from './components/SwapPage';
import Settings from './components/Settings';
import Transfer from './components/Transfer';
import History from './components/History';
import LandingPage from './components/LandingPage';
import LinksPage from './components/LinksPage';
import './App.css';

function App() {
    return (
        <AuthProvider>
            <Navbar />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/walletpage" element={<PrivateRoute element={WalletPage} />} />
                <Route path="/swappage" element={<PrivateRoute element={SwapPage} />} />
                <Route path="/settings" element={<PrivateRoute element={Settings} />} />
                <Route path="/transfer" element={<PrivateRoute element={Transfer} />} />
                <Route path="/history" element={<PrivateRoute element={History} />} />
                <Route path="/linkspage" element={<PrivateRoute element={LinksPage} />} />
            </Routes>
        </AuthProvider>
    );
}

export default App;
