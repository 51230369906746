import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { InputField, Button } from '../FormComponents';
import './Login.css';

function Login() {
    const { signInWithEmail } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmail(email, password);
            navigate('/walletpage');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="login-page">
            <div className="form-container">
                <h1>Login</h1>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleLogin}>
                    <InputField type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                    <InputField type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                    <Button className="button-login">Log In</Button>                </form>
                <p className="switch-message">Don't have an account? <Link to="/signup">Sign Up</Link></p>
            </div>
        </div>
    );
}

export default Login;
