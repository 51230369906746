import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { InputField, Button } from '../FormComponents';
import './Login.css'; // Reuse the same CSS for simplicity

function SignUp() {
    const { signUpWithEmail } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSignUp = async (event) => {
        event.preventDefault();
        try {
            await signUpWithEmail(email, password);
            navigate('/walletpage');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="login-page">
            <div className="form-container">
                <h1>Sign Up</h1>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSignUp}>
                    <InputField type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                    <InputField type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                    <Button className="button-login">Sign Up</Button>                </form>
                
                <p className="switch-message">Already have an account? <Link to="/login">Log In</Link></p>
              
            </div>
        </div>
    );
}

export default SignUp;
