import React from 'react';
import './App.css';

export const InputField = ({ type, value, onChange, placeholder }) => (
    <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="input-field"
        required
    />
);

export const Button = ({ onClick, children, className = "button", disabled = false }) => (
    <button onClick={onClick} className={className} disabled={disabled}>
        {children}
    </button>
);
