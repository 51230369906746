import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase';
import { query, collection, getDocs } from 'firebase/firestore';
import { InputField, Button } from '../FormComponents';
import './Transfer.css';
import { FaArrowUp } from 'react-icons/fa';

function Transfer() {
    const [recipientAddress, setRecipientAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState('');
    const [loading, setLoading] = useState(false);
    const [sendStatus, setSendStatus] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [filteredTokens, setFilteredTokens] = useState([]);

    useEffect(() => {
        fetchTokens();
    }, []);

    useEffect(() => {
        filterTokens(tokenSymbol, setFilteredTokens);
    }, [tokenSymbol, tokens]);

    const fetchTokens = async () => {
        try {
            const tokensSnapshot = await getDocs(collection(db, '1inchtoken'));
            const fetchedTokens = tokensSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTokens(fetchedTokens);
        } catch (error) {
            setSendStatus('Failed to fetch tokens.');
        }
    };

    const filterTokens = (search, setFilteredTokens) => {
        if (search.trim() === '') {
            setFilteredTokens([]);
            return;
        }
        const filteredTokens = tokens.filter(token =>
            token.symbol.toLowerCase().startsWith(search.toLowerCase())
        );
        setFilteredTokens(filteredTokens);
    };

    const fetchMaxAmount = async () => {
        const user = auth.currentUser;
        if (!user) return;
        try {
            const token = await user.getIdToken();
            const response = await axios.post('https://smart.tradingrobots.io/getMaxTransferAmount', {
                userId: user.uid,
                tokenSymbol
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setAmount(response.data.maxTransferAmount);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setSendStatus(`Failed to fetch max amount: ${error.message}`);
        }
    };

    const sendTransaction = async (event) => {
        event.preventDefault();
        const user = auth.currentUser;
        if (!user) return;
        setLoading(true);
        try {
            const token = await user.getIdToken();
            const response = await axios.post('https://smart.tradingrobots.io/sendTransaction', {
                recipientAddress,
                amount,
                tokenSymbol,
                userId: user.uid
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setSendStatus('Transaction successful!');
                setShowPopup(true);
                setRecipientAddress('');
                setAmount('');
                setTokenSymbol('');
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setSendStatus(`Transaction failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
      <div className="transfer-page">
      <div className="swap-header">
          <FaArrowUp size={30} className="swap-icon" />
          <h1 className="centered-title">Send</h1>
      </div>
            <div className="form-container-login">
                <form onSubmit={sendTransaction} className="transfer-form">
                    <InputField type="text" value={recipientAddress} onChange={e => setRecipientAddress(e.target.value)} placeholder="Recipient Address" />
                    <div className="token-select">
                        <InputField type="text" value={tokenSymbol} onChange={e => setTokenSymbol(e.target.value)} placeholder="Token Symbol" />
                        <div className="amount-input">
                            <InputField type="text" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Amount" />
                            <Button onClick={fetchMaxAmount} className="max-button">Max</Button>
                        </div>
                        <ul className="token-list">
                            {filteredTokens.map(token => (
                                <li key={token.address} onClick={() => setTokenSymbol(token.symbol)}>
                                    {token.name} ({token.symbol})
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Button type="submit" disabled={loading}>Send</Button>
                </form>
                {loading && <div className="loading-spinner"></div>}
                {sendStatus && <p className={sendStatus.startsWith('Transaction successful') ? 'success-message' : 'error-message'}>{sendStatus}</p>}
            </div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>{sendStatus}</h2>
                        <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Transfer;
