import React, { useState } from 'react';
import SignUp from './SignUp';
import Login from './Login';
import './LandingPage.css';

function LandingPage() {
    const [isLoginView, setIsLoginView] = useState(true);

    const toggleView = () => setIsLoginView(!isLoginView);

    return (
        <div className="landing-page">
            <div className="background-image" />
            <div className="container">
                {isLoginView ? <Login /> : <SignUp />}
                <button onClick={toggleView} className="toggle-button">
                    {isLoginView ? "Don't have an account? Sign Up" : "Already have an account? Log In"}
                </button>
            </div>
        </div>
    );
}

export default LandingPage;
