import { useState, useEffect, useContext, createContext } from 'react';
import { auth } from '../firebase';  // Ensure this import correctly points to your Firebase configuration file
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut as firebaseSignOut,
    onAuthStateChanged
} from 'firebase/auth';

const AuthContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    // Email and password sign-up
    const signUpWithEmail = async (email, password) => {
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password);
            setUser(result.user);
            return result.user;
        } catch (error) {
            console.error("Error signing up with email and password:", error);
            throw error;
        }
    };

    // Email and password sign-in
    const signInWithEmail = async (email, password) => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            setUser(result.user);
            return result.user;
        } catch (error) {
            console.error("Error signing in with email and password:", error);
            throw error;
        }
    };

    // Sign out
    const signOut = async () => {
        try {
            await firebaseSignOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
            throw error;
        }
    };

    // Handle user state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
            if (firebaseUser) {
                setUser(firebaseUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();  // Cleanup subscription
    }, []);

    return {
        user,
        signUpWithEmail,
        signInWithEmail,
        signOut
    };
}
