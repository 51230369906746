import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { InputField, Button } from '../FormComponents';
import './SwapPage.css';

function SwapPage({ onClose, onSuccess }) {
    const [user, setUser] = useState(null);
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [srcTokenSymbol, setSrcTokenSymbol] = useState('');
    const [dstTokenSymbol, setDstTokenSymbol] = useState('');
    const [tokens, setTokens] = useState([]);
    const [filteredSrcTokens, setFilteredSrcTokens] = useState([]);
    const [filteredDstTokens, setFilteredDstTokens] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(setUser);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        fetchTokens();
    }, []);

    useEffect(() => {
        filterTokens(srcTokenSymbol, setFilteredSrcTokens);
    }, [srcTokenSymbol, tokens]);

    useEffect(() => {
        filterTokens(dstTokenSymbol, setFilteredDstTokens);
    }, [dstTokenSymbol, tokens]);

    const fetchTokens = async () => {
        try {
            const tokensSnapshot = await getDocs(collection(db, '1inchtoken'));
            const fetchedTokens = tokensSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTokens(fetchedTokens);
        } catch (error) {
            setMessage('Failed to fetch tokens.');
        }
    };

    const filterTokens = (search, setFilteredTokens) => {
        if (search.trim() === '') {
            setFilteredTokens([]);
            return;
        }
        const filteredTokens = tokens.filter(token =>
            token.symbol.toLowerCase().startsWith(search.toLowerCase())
        );
        setFilteredTokens(filteredTokens);
    };

    const handleTransaction = async (url, data) => {
        try {
            const idToken = await user.getIdToken();
            const response = await axios.post(url, data, {
                headers: { Authorization: `Bearer ${idToken}` }
            });

            if (response.status === 200 && response.data.success) {
                setMessage(`Transaction successful! Transaction Hash: ${response.data.transactionReceipt.transactionHash}`);
                onSuccess(); // Notify success
            } else {
                setMessage('Transaction failed: ' + (response.data.message || 'No additional error information.'));
            }
        } catch (error) {
            setMessage('An error occurred during the transaction: ' + error.message);
        }
    };

    const handleSwapTokens = async () => {
        if (!user) {
            setMessage('Please log in to perform this operation.');
            return;
        }

        if (!amount || !srcTokenSymbol || !dstTokenSymbol) {
            setMessage('Please enter an amount and select both tokens.');
            return;
        }

        onClose(); // Close the modal immediately

        await handleTransaction('https://smart.tradingrobots.io/swapTokens', {
            userId: user.uid,
            amount,
            srcTokenSymbol,
            dstTokenSymbol
        });
    };

    const handleSwap = async () => {
        if (!user) {
            setMessage('Please log in to perform this operation.');
            return;
        }

        if (!amount) {
            setMessage('Please enter an amount to swap.');
            return;
        }

        onClose(); // Close the modal immediately

        await handleTransaction('https://smart.tradingrobots.io/swap', {
            userId: user.uid,
            amount
        });
    };

    const handleSwapInv = async () => {
        if (!user) {
            setMessage('Please log in to perform this operation.');
            return;
        }

        if (!amount) {
            setMessage('Please enter an amount to swap.');
            return;
        }

        onClose(); // Close the modal immediately

        await handleTransaction('https://smart.tradingrobots.io/swapinv', {
            userId: user.uid,
            amount
        });
    };

    const handleSellAllMatic = async () => {
        if (!user) {
            setMessage('Please log in to perform this operation.');
            return;
        }

        onClose(); // Close the modal immediately

        await handleTransaction('https://smart.tradingrobots.io/sell-matic', {
            userId: user.uid
        });
    };

    return (
        <div className="modal-content">
            <div className="form-container-login">
                <div className="input-group">
                    <div className="token-select">
                        <InputField type="text" value={srcTokenSymbol} onChange={e => setSrcTokenSymbol(e.target.value)} placeholder="Source" />
                        <ul className="token-list">
                            {filteredSrcTokens.map(token => (
                                <li key={token.address} onClick={() => setSrcTokenSymbol(token.symbol)}>
                                    {token.name} ({token.symbol})
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="token-select">
                        <InputField type="text" value={dstTokenSymbol} onChange={e => setDstTokenSymbol(e.target.value)} placeholder="Destination" />
                        <ul className="token-list">
                            {filteredDstTokens.map(token => (
                                <li key={token.address} onClick={() => setDstTokenSymbol(token.symbol)}>
                                    {token.name} ({token.symbol})
                                </li>
                            ))}
                        </ul>
                    </div>
                    <InputField type="number" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Amount" />
                </div>
                <div className="button-group">
                    <Button onClick={handleSwapTokens}>Swap</Button>
                    <Button onClick={handleSwap}>MATIC to USDT</Button>
                    <Button onClick={handleSwapInv}>USDT to MATIC</Button>
                    <Button onClick={handleSellAllMatic}>Sell All MATIC</Button>
                </div>
                {!user && <p>Please log in to swap tokens.</p>}
            </div>
            {message && <p className={message.includes('successful') ? 'success-message' : 'error-message'}>{message}</p>}
        </div>
    );
}

export default SwapPage;
