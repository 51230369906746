import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { FaCopy, FaGoogle, FaExchangeAlt, FaArrowDown, FaArrowUp, FaHistory } from 'react-icons/fa';
import './WalletPage.css';
import Transfer from './Transfer';
import SwapPage from './SwapPage';

function fromWei(value) {
    return value / 1e18; // Convert wei to ether
}

function WalletPage() {
    const [user, setUser] = useState(null);
    const [wallet, setWallet] = useState({ address: '', tokens: {} });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [maticPrice, setMaticPrice] = useState('');
    const [maticValueUSD, setMaticValueUSD] = useState('');
    const [totalBalanceUSD, setTotalBalanceUSD] = useState('');
    const [copied, setCopied] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [transactionSuccess, setTransactionSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchMaticPrice();
        const interval = setInterval(fetchMaticPrice, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (wallet.tokens && wallet.tokens.MATIC && maticPrice) {
            const maticBalance = parseFloat(wallet.tokens.MATIC.balance_formatted);
            const usdValue = maticBalance * maticPrice;
            setMaticValueUSD(usdValue.toFixed(2));
        }
    }, [wallet, maticPrice]);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(async (fetchedUser) => {
            setUser(fetchedUser);
            if (fetchedUser) {
                setError('');
                fetchWallet(fetchedUser.uid);
            }
        });

        return () => {
            unsubscribeAuth();
        };
    }, [user]);

    const fetchWallet = async (userId) => {
        setLoading(true);
        setError('');
        const walletRef = doc(db, 'wallets', userId);
        try {
            const walletDoc = await getDoc(walletRef);
            if (walletDoc.exists()) {
                const walletData = walletDoc.data();
                setWallet(walletData);
                fetchBalance(walletData.address);
            } else {
                setError('No wallet found. Please create one.');
                setWallet({ address: '', tokens: {} });
            }
        } catch (error) {
            console.error("Error fetching wallet:", error);
            setError('Failed to fetch wallet. Please try again.');
            setWallet({ address: '', tokens: {} });
        } finally {
            setLoading(false);
        }
    };

    const fetchBalance = (walletAddress) => {
        setLoading(true);
        setError('');
        const balanceRef = doc(db, 'balance', walletAddress.toLowerCase());
        try {
            onSnapshot(balanceRef, (doc) => {
                if (doc.exists()) {
                    const balanceData = doc.data();
                    if (balanceData.tokens) {
                        setWallet(prevWallet => ({ ...prevWallet, tokens: balanceData.tokens }));
                    } else {
                        setError('Token balance information not found.');
                    }
                } else {
                    setError('Balance document not found.');
                }
                setLoading(false);
            });
        } catch (error) {
            console.error("Error fetching balance:", error);
            setError('Failed to fetch balance. Please try again.');
            setLoading(false);
        }
    };

    const createWallet = async () => {
        if (!user) {
            setError('No user signed in.');
            setLoading(false);
            return;
        }

        setLoading(true);
        setError('');

        try {
            const token = await user.getIdToken();
            const response = await axios.post('https://smart.tradingrobots.io/wallet', {}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                setWallet(response.data.wallet);
                fetchBalance(response.data.wallet.address);
            } else {
                throw new Error(response.data.message || 'Failed to create wallet.');
            }
        } catch (error) {
            console.error("Error creating wallet:", error);
            setError('Failed to create wallet. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchMaticPrice = async () => {
        const apiKey = 'bc91faaf68f5658dd1dd4c35bf0ded25f99bcdc03c50252021953085d6aad527'; // Your CryptoCompare API key
        try {
            const url = `https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD&api_key=${apiKey}`;
            const response = await axios.get(url);
            if (response.data && response.data.USD) {
                setMaticPrice(response.data.USD);
            } else {
                console.error('Failed to fetch MATIC price, response:', response.data);
                setError('MATIC price data is unavailable.');
            }
        } catch (error) {
            console.error('Failed to fetch MATIC price:', error);
            setError('Failed to fetch MATIC price due to an error.');
        }
    };

    const calculateTotalBalance = () => {
        let totalBalance = 0;

        // Calculate the value of MATIC in USD
        if (wallet.tokens && wallet.tokens.MATIC) {
            const maticBalance = parseFloat(wallet.tokens.MATIC.balance_formatted);
            totalBalance += maticBalance * maticPrice;
        }

        // Calculate the value of USDT in USD (1 USDT = 1 USD)
        if (wallet.tokens && wallet.tokens.USDT) {
            const usdtBalance = parseFloat(wallet.tokens.USDT.balance_formatted);
            totalBalance += usdtBalance;
        }

        setTotalBalanceUSD(totalBalance.toFixed(2));
    };

    useEffect(() => {
        calculateTotalBalance();
        const interval = setInterval(calculateTotalBalance, 5000);
        return () => clearInterval(interval);
    }, [wallet.tokens, maticPrice]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }, (err) => {
            console.error('Failed to copy: ', err);
        });
    };

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setTransactionSuccess(false); // Reset transaction success state
    };

    const handleTransactionSuccess = () => {
        setTransactionSuccess(true);
        setModalIsOpen(false); // Close the modal when transaction is successful
    };

    const renderModalContent = () => {
        switch (modalType) {
            case 'send':
                return <Transfer onSuccess={handleTransactionSuccess} onClose={closeModal} />;
            case 'swap':
                return <SwapPage onSuccess={handleTransactionSuccess} onClose={closeModal} />;
            case 'receive':
                return (
                    <div className="receive-modal">
                        <QRCode value={wallet.address} />
                        <p>{wallet.address}</p>
                        <button onClick={() => copyToClipboard(wallet.address)} className="copy-button">
                            <FaCopy /> Copy Address
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    const goToHistory = () => {
        navigate('/history');
    };

    return (
        <div className="wallet-page">
            <div className="wallet-container">
                <h1>Wallet</h1>
                {user ? (
                    <div>
                        {loading && <p>Loading...</p>}
                        {error && <p className="error">{error}</p>}
                        {wallet.address ? (
                            <div>
                                <div className="address-container">
                                    <div className="address-preview">
                                        <p>{`${wallet.address.slice(0, 6)}...${wallet.address.slice(-4)}`}</p>
                                        <button onClick={() => copyToClipboard(wallet.address)} className="copy-button">
                                            <FaCopy />
                                        </button>
                                    </div>
                                    <div className="price-card-container">
                                        <div className="price-card">
                                            <span className="total-balance-label">
                                                <span className="value">${totalBalanceUSD ? totalBalanceUSD : 'Calculating...'}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="action-buttons">
                                    <div className="action-button-container">
                                        <button className="action-button" onClick={() => openModal('swap')}>
                                            <FaExchangeAlt size={30} />
                                        </button>
                                        <span className="button-label">Swap</span>
                                    </div>
                                    <div className="action-button-container">
                                        <button className="action-button" onClick={() => openModal('send')}>
                                            <FaArrowUp size={30} />
                                        </button>
                                        <span className="button-label">Send</span>
                                    </div>
                                    <div className="action-button-container">
                                        <button className="action-button" onClick={() => openModal('receive')}>
                                            <FaArrowDown size={30} />
                                        </button>
                                        <span className="button-label">Receive</span>
                                    </div>
                                </div>
                                <div className="token-card-container">
                                    <div className="balances-section">
                                        <div className="token-list">
                                            {wallet.tokens && Object.entries(wallet.tokens).map(([symbol, tokenData]) => (
                                                <div key={symbol} className="token-item">
                                                    <img 
                                                        src={symbol === 'USDT' ? 'https://cryptologos.cc/logos/tether-usdt-logo.png' : tokenData.logo} 
                                                        alt={symbol} 
                                                        className="token-logo" 
                                                    />
                                                    <div className="token-info">
                                                        <strong>{symbol}</strong> 
                                                        <span className="value">{parseFloat(tokenData.balance_formatted).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <button onClick={createWallet} disabled={loading}>
                                Create Wallet
                            </button>
                        )}
                    </div>
                ) : (
                    <button className="google-button" onClick={() => auth.signInWithPopup(new auth.GoogleAuthProvider())}>
                        <FaGoogle /> Sign in with Google
                    </button>
                )}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="modal"
                overlayClassName="overlay"
            >
                {renderModalContent()}
                <button onClick={closeModal} className="close-button">&times;</button>
            </Modal>
            {transactionSuccess && (
                <div className="success-message-container">
                    <h2>Transaction Successful!</h2>
                    <button onClick={() => setTransactionSuccess(false)} className="close-button">X</button>
                    <button onClick={goToHistory} className="button">
                        <FaHistory size={20} /> History
                    </button>
                </div>
            )}
        </div>
    );
}

export default WalletPage;
