import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import Modal from 'react-modal';
import axios from 'axios';
import './Settings.css';  // Ensure this is linked correctly
import { FaSignOutAlt } from 'react-icons/fa';  // Icon for the logout button

Modal.setAppElement('#root'); // This ensures accessibility for screen readers

function Settings() {
    const [user, setUser] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [privateKey, setPrivateKey] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unsubscribe();
    }, []);

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setPassword('');
        setError('');
        setPrivateKey('');
    };

    const reAuthenticateAndRetrieveKey = async () => {
        if (!user) {
            setError('No user is logged in.');
            return;
        }

        const credential = EmailAuthProvider.credential(user.email, password);
        try {
            console.log('Re-authenticating...');
            await reauthenticateWithCredential(user, credential);
            console.log('Re-authenticated successfully. Fetching private key...');
            const token = await user.getIdToken();
            const response = await axios.get('https://smart.tradingrobots.io/getPrivateKey', {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Received response:', response);
            if (response.data.success) {
                setPrivateKey(response.data.privateKey);
                setError('');
            } else {
                throw new Error('Failed to retrieve private key');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(`Authentication failed: ${error.message}`);
        }
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            console.log("Logged out successfully!");
        }).catch((error) => {
            setError(`Logout failed: ${error.message}`);
        });
    };

    const copyToClipboard = () => {
        if (!privateKey) {
            setError('No private key to copy.');
            return;
        }
        
        navigator.clipboard.writeText(privateKey).then(() => {
            alert('Private key copied to clipboard!');
        }, (err) => {
            setError('Failed to copy private key: ' + err);
        });
    };

    return (
        <div className="settings-page">
            <h1>Account Management</h1>
            <div className="settings-content">
                <p>Welcome, {user ? user.email : 'loading...'}</p>
                <button className="settings-button" onClick={handleOpenModal}>Retrieve Private Key</button>
                <div className="logout-section">
                    <button onClick={handleLogout} className="logout-button">
                        <FaSignOutAlt size={20} /> Logout
                    </button>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Private Key Retrieval"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <h2>Enter Password</h2>
<input 
    type="password" 
    value={password} 
    onChange={(e) => setPassword(e.target.value)} 
    placeholder="Enter your password"
    className="inputPasword" 
/>                    <button onClick={reAuthenticateAndRetrieveKey}>Retrieve Key</button>
                    {privateKey && (
                        <div>
                            <p>Your Private Key: {privateKey}</p>
                            <button onClick={copyToClipboard}>Copy to Clipboard</button>
                        </div>
                    )}
                    {error && <p className="error">{error}</p>}
                    <button onClick={handleCloseModal}>Close</button>
                </div>
            </Modal>
        </div>
    );
}

export default Settings;
