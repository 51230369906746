import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaWallet, FaHistory, FaSearch, FaCog } from 'react-icons/fa';
import { useAuth } from './AuthContext'; // Import useAuth hook
import '../App.css';

function Navbar() {
    const location = useLocation();
    const { currentUser } = useAuth(); // Access currentUser from useAuth

    // If the user is not authenticated, return null to not render the Navbar
    if (!currentUser) {
        return null;
    }

    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li className={location.pathname === '/walletpage' ? 'active' : ''}>
                    <Link to="/walletpage" className={`nav-button ${location.pathname === '/walletpage' ? 'active' : ''}`}>
                        <FaWallet size={30} />
                        <span>Wallet</span>
                    </Link>
                </li>
                <li className={location.pathname === '/history' ? 'active' : ''}>
                    <Link to="/history" className={`nav-button ${location.pathname === '/history' ? 'active' : ''}`}>
                        <FaHistory size={30} />
                        <span>History</span>
                    </Link>
                </li>
                <li className={location.pathname === '/linkspage' ? 'active' : ''}>
                    <Link to="/linkspage" className={`nav-button ${location.pathname === '/linkspage' ? 'active' : ''}`}>
                        <FaSearch size={30} />
                        <span>Browse</span>
                    </Link>
                </li>
                <li className={location.pathname === '/settings' ? 'active' : ''}>
                    <Link to="/settings" className={`nav-button ${location.pathname === '/settings' ? 'active' : ''}`}>
                        <FaCog size={30} />
                        <span>Settings</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
