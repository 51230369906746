import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { auth, db } from '../firebase';
import Modal from 'react-modal';
import './History.css';
import { FaArrowUp, FaArrowDown, FaExchangeAlt, FaTimes } from 'react-icons/fa';

function fromWei(value) {
    return value / 1e18;  // Convert wei to ether
}

function History() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('User is logged in:', user.email);
                const walletAddress = await fetchWalletAddress(user.uid);
                console.log('User wallet address:', walletAddress);
                setWalletAddress(walletAddress.toLowerCase());
                fetchTransactions(walletAddress.toLowerCase());
            } else {
                console.log('No user is logged in');
            }
        });

        return () => unsubscribeAuth();
    }, []);

    const fetchWalletAddress = async (userId) => {
        try {
            const walletRef = doc(db, 'wallets', userId);
            const walletDoc = await getDoc(walletRef);
            if (walletDoc.exists()) {
                const walletData = walletDoc.data();
                return walletData.address;
            } else {
                setError('No wallet found. Please create one.');
                return '';
            }
        } catch (error) {
            console.error("Error fetching wallet address:", error);
            setError('Failed to fetch wallet address. Please try again.');
            return '';
        }
    };

    const fetchTransactions = (address) => {
        setLoading(true);
        setError('');

        const transactionsRef = collection(db, 'transactions');
        const qToAddress = query(transactionsRef, where('toAddress', '==', address));
        const qFromAddress = query(transactionsRef, where('fromAddress', '==', address));

        const unsubscribeToAddress = onSnapshot(qToAddress, (snapshot) => {
            const toAddressTransactions = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    ...data,
                    id: doc.id,
                    type: parseFloat(data.value) === 0 ? 'Swap' : 'Received',
                    displayValue: parseFloat(data.value) === 0 ? 'Swap' : `+${fromWei(data.value)}`,
                    value: fromWei(data.value),
                    time: new Date(data.timestamp.seconds * 1000).toLocaleTimeString()
                };
            });
            setTransactions(prevTransactions => [...prevTransactions, ...toAddressTransactions]);
            setLoading(false);
        }, (error) => {
            console.error("Error fetching toAddress transactions:", error);
            setError('Failed to fetch transactions. Please try again.');
        });

        const unsubscribeFromAddress = onSnapshot(qFromAddress, (snapshot) => {
            const fromAddressTransactions = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    ...data,
                    id: doc.id,
                    type: parseFloat(data.value) === 0 ? 'Swap' : 'Sent',
                    displayValue: parseFloat(data.value) === 0 ? 'Swap' : `-${fromWei(data.value)}`,
                    value: fromWei(data.value),
                    time: new Date(data.timestamp.seconds * 1000).toLocaleTimeString()
                };
            });
            setTransactions(prevTransactions => [...prevTransactions, ...fromAddressTransactions]);
            setLoading(false);
        }, (error) => {
            console.error("Error fetching fromAddress transactions:", error);
            setError('Failed to fetch transactions. Please try again.');
        });

        return () => {
            unsubscribeToAddress();
            unsubscribeFromAddress();
        };
    };

    const getIcon = (type) => {
        switch (type) {
            case 'Received':
                return <FaArrowDown className="transaction-icon" />;
            case 'Sent':
                return <FaArrowUp className="transaction-icon" />;
            case 'Swap':
                return <FaExchangeAlt className="transaction-icon" />;
            default:
                return null;
        }
    };

    return (
        <div className="history-page">
            <h2>History</h2>
            {walletAddress && <p> {walletAddress}</p>}
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <ul className="transaction-list">
                {transactions.length > 0 ? (
                    transactions.map((transaction) => (
                        <li key={transaction.id} className="transaction-item" onClick={() => setSelectedTransaction(transaction)}>
                            <div className="icon-circle">
                                {getIcon(transaction.type)}
                            </div>
                            <div className="transaction-info">
                                <p className="transaction-type">{transaction.type}</p>
                                <p className={`transaction-value ${transaction.type.toLowerCase()}`}>{transaction.displayValue}</p>
                                <p className="transaction-time">{transaction.time}</p>
                            </div>
                        </li>
                    ))
                ) : <p>Loading...</p>}
            </ul>
            {selectedTransaction && (
                <Modal
                    isOpen={!!selectedTransaction}
                    onRequestClose={() => setSelectedTransaction(null)}
                    contentLabel="Transaction Details"
                    className="modal"
                    overlayClassName="overlay"
                    ariaHideApp={false}
                >
                    <button onClick={() => setSelectedTransaction(null)} className="close-modal-button"><FaTimes /></button>
                    <form className="transaction-details-form">
                        <label>
                            Type:
                            <input type="text" value={selectedTransaction.type} readOnly className="transaction-input" />
                        </label>
                        <label>
                            From:
                            <input type="text" value={selectedTransaction.fromAddress} readOnly className="transaction-input" />
                        </label>
                        <label>
                            To:
                            <input type="text" value={selectedTransaction.toAddress} readOnly className="transaction-input" />
                        </label>
                        <label>
                            Value:
                            <input type="text" value={selectedTransaction.displayValue} readOnly className="transaction-input" />
                        </label>
                        <label>
                            Date:
                            <input type="text" value={new Date(selectedTransaction.timestamp.seconds * 1000).toLocaleString()} readOnly className="transaction-input" />
                        </label>
                        <label>
                            Hash:
                            <input type="text" value={selectedTransaction.hash} readOnly className="transaction-input" />
                        </label>
                        <a href={`https://polygonscan.com/tx/${selectedTransaction.hash}`} target="_blank" rel="noopener noreferrer" className="blockchain-button">View in Explorer</a>
                    </form>
                </Modal>
            )}
        </div>
    );
}

export default History;
